import Swiper from 'swiper';
import { Autoplay, Scrollbar } from 'swiper/modules';
const sectionSliderClass = '.js-section-slider';
const sectionSlider = new Swiper(sectionSliderClass, {
    modules: [Scrollbar, Autoplay],
    slidesPerView: 1.25,
    spaceBetween: 32,
    scrollbar: {
        el: '.js-swiper-scrollbar',
        draggable: true,
    },
    breakpoints: {
        991: {
            slidesPerView: 1.25,
        },
        1200: {
            slidesPerView: 2.25,
        },
        1400: {
            slidesPerView: 2.25,
        },
    },
});
